import React from "react"
import { Link } from "gatsby"
import { BreadCrumb, Container } from "../../components/Section"
import {
  HeroSection,
  HeroCover,
  HeroPageTitle,
  HeroDesc,
} from "../../components/Hero"

class ProductRoofHero extends React.Component {
  render() {
    const { data, product } = this.props
    const categoryUrl = data.productCategory.name
      .split(" ")
      .join("-")
      .toLowerCase()

    return (
      <>
        {product.length !== 0 && (
          <HeroSection>
            <Container>
              <BreadCrumb className="static">
                <Link to="/">Home</Link>
                <span style={{ padding: '0 4px' }}>/</span>
                <Link to={`/${categoryUrl}/`}>
                  {data.productCategory.name}
                </Link>
                <span style={{ padding: '0 4px' }}>/</span>
                <span>
                  {data.productRoofType.name}{" "}
                  {data.productRoofType.name === "Metal Barn Kits" ||
                    data.productRoofType.name === "Metal Carport Kits" ||
                    data.productRoofType.name === "Metal Garage Kits" ||
                    data.productRoofType.name === "Metal Buildings Kits"
                    ? null
                    : data.productCategory.name}
                </span>
              </BreadCrumb>
              <HeroCover textAlign="center">
                <HeroPageTitle>{data.heroTitle}</HeroPageTitle>
                <HeroDesc maxWidth="1130px" mb="0"
                  dangerouslySetInnerHTML={{
                    __html: data.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </HeroCover>
              {/* <HeroCover>
                <LeftHero>
                  <HeroPageTitle>{data.heroTitle}</HeroPageTitle>
                  <HeroDesc
                    dangerouslySetInnerHTML={{
                      __html: data.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </LeftHero>
                <RightHero>
                  <ProductImageCarousel product={product} />
                </RightHero>
              </HeroCover> */}
            </Container>
          </HeroSection>
        )}
        {product.length === 0 && (
          <HeroSection>
            <Container>
              <BreadCrumb className="static">
                <Link to="/">Home</Link> / {data.productCategory.name} /{" "}
                <span>{data.productRoofType.name} </span>
              </BreadCrumb>
              <HeroCover>
                <HeroPageTitle>{data.heroTitle}</HeroPageTitle>
                <HeroDesc
                  dangerouslySetInnerHTML={{
                    __html: data.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </HeroCover>
            </Container>
          </HeroSection>
        )}
      </>
    )
  }
}

export default ProductRoofHero
